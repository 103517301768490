.wrapper {
  position: relative;
}

.search-section > div {
  margin: 2vw 16vw;
}

.search-section > div > h1 {
  text-align: left;
}

.search-bar {
  display: flex;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover { 
    cursor:pointer; 
}

.search-bar form {
  width: 100%;
}

.title-only-switch {
  padding: 15px;
  width: 250px;
}

.title-only-switch .label {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 18px;
  margin-left: 16px;
}

.section-full .container {
  margin-bottom: 2rem;
}
