.content-box {
  box-shadow: rgba(231,231,231,1) 0 2px 8px;
  position: relative;
}

.search-filters {
  margin: 0 auto;
  text-align: left;
}

.search-filter-label {
  display: inline-block;
  padding: 0 2vh;
}

.search-filters .DayPicker-Day {
  padding: .375em .5em;
}

.search-filters .grid > div {
  padding-top: 0;
}

.form-label {
  color: #041c2c;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  box-sizing: border-box;
  font-family: "Proxima Nova",Arial,sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
  display: block;
  margin-bottom: .4rem;
}
