.doc-error {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  height: 50vh;
}

section.doc-view-page > .doc-panels {
  display: flex;
  flex-direction: row;
}

section.doc-view-page > .doc-panels > .doc-metadata {
  flex: 1;
  text-align: left;
  padding: 25px 16px;
}

section.doc-view-page > .doc-panels > .doc-preview {
  position: relative;
  flex: 2;
  padding-top: 16px;
}

div.download-block {
  margin: 24px 0;
}

section.doc-view-page .doc-metadata .doc-info {
  margin-bottom: 24px;
  font-size: 18px;
}

section.doc-view-page .doc-metadata p {
  margin: 8px 0;
}

section.doc-view-page .doc-metadata h4 {
  margin: 24px 0 8px;
}

.one-liner {
  white-space: nowrap;
}

.doc-metadata .grid {
  display: grid;
  border: solid 1px #ddd;
  row-gap: 0;
}

.doc-metadata .grid div {
  padding: 8px;
}

.doc-metadata .grid strong {
  white-space: nowrap;
}

.document-used-in-table {
  grid-template-columns: repeat(4, 1fr);
}

.doc-metadata .document-used-in-table {
  border-top: solid 1px #ddd;
  border-left: solid 1px #ddd;
  border-right: 0 none;
  border-bottom: 0 none;
  row-gap: 0;
}

.document-used-in-table div {
  border-right: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

.document-used-in-table .link {
  grid-column: span 4;
}


.document-rules-n-usages-table {
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
}

.document-rules-n-usages-table div:nth-child(n + 5) {
  border-left: solid 1px #ddd;
}

.document-rules-n-usages-table div:not(:nth-child(4n)) {
  border-bottom: solid 1px #ddd;
}

.document-implementation-notes-table {
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
}

.document-implementation-notes-table div:nth-child(n + 6) {
  border-left: solid 1px #ddd;
}

.document-implementation-notes-table div:not(:nth-child(5n)) {
  border-bottom: solid 1px #ddd;
}

.document-juristictions-table {
  grid-template-columns: repeat(4, 1fr);
}

.document-juristictions-table div:not(:nth-child(4n)) {
  border-right: solid 1px #ddd;
}

.document-juristictions-table div:nth-child(n + 5) {
  border-top: solid 1px #ddd;
}
