.export-section {
  text-align: left;
}

.export-filters {
  margin-top: 2vw;
  text-align: left;
}

.export-filters .DayPickerInput {
  width: 100%;
}

.export-filters .DayPickerInput > input {
  width: 100%;
}

.export-filters .DayPicker-Day {
  padding: .375em .5em;
}

