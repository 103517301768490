.cell {
  display: flow-root;
}

.listing-card-row {
  cursor: pointer;
  height: auto;
  padding: .5rem 2rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.listing-card-row:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.listing-card-row-results .listing-card-row:first-child {
  margin-top: 0;
}

.listing-card-row-results .listing-card-row {
  align-items: baseline;
}

.listing-card-row .tag--primary {
  position: absolute;
  top: 4px;
  left: -22px;
  font-size: 12px;
  height: 16px;
}

.listing-card-row .cell:last-child {
  padding-right: 0;
}

.listing-card-row .content-center {
  text-align: center;
  display: flex;
  justify-content: center;
}

.listing-card-row .subcell {
  display: flex;
}

.listing-card-row .subcell > p {
  flex-direction: row;
  display: flex;
  padding-right: 8px;
}

.listing-card-row p.content {
  display: inline-block;
  font-size: 14px;
  line-height: 1rem;
  margin-top: auto;
  max-width:100%;
  overflow: hidden;
  padding: 1rem 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-card-row p.inline-labels {
  display: inline-block;
  padding: 0 4px 0 0;
}

.tag--actions {
  position: absolute;
  right: 3px;
  top: 3px;
}

.infinite-footer {
  position: fixed;
  bottom: 0em;
  width: 100%;
  z-index: -10;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;

}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.listing-card-row-seperator {
  margin-top: 3px;
  background-color: #ecf1f9;
  position:relative;
}

.positioning {
  display: flex;
  flex-direction: column;
}
