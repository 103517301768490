.listing-card-row__set {
  width: calc(100% - 4em);
  padding: 0 0 2em 0;
  margin: 0 2em;
}

.listing-card-header {
  align-items: center;
  background-color: #ecf1f9;
  border-radius:0;
  border: 0 none;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1rem;
  height: auto;
  justify-content: space-between;
  margin-bottom:0;
  min-height: 60px;
  position: sticky;
  top:0;
  width: 100%;
  z-index:10;
}

.listing-card-header:hover {
  box-shadow: none;
}

.listing-card-row__set > .listing-card-header {
  cursor: default;
}

.listing-card-header span.block {
  display: block;
}

.listing-card-header span.match {
  font-size: 14px;
  font-weight: normal;
  padding-top:3px;
}

.listing-card-header .subcell {
  display: flex;
}

.listing-card-header .subcell > p {
  font-size: 14px;
  flex-direction: row;
  display: flex;
}

.listing-card-header .cell {
  padding-right: 10px;
}

.listing-card-row .cell {
  position: relative;
  padding-right: 10px;
}

.listing-card-row .cell:last-child {
  padding-right: 0;
}

.listing-card-header .text-center {
  text-align: center;
}

.listing-card-row-results > div:first-child {
  padding-top: 0;
}
