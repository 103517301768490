.columns {
  display: flex;
  flex-direction: row;
}

.forms-list {
  flex: 3;
  text-align: left;
}

.sidebar {
  flex: 2;
  margin: 20px;
}

.forms-list-item {
  margin: 5px 0;
  padding: 5px;
  background-color: aquamarine;
  color: darkcyan;
}

.forms-list-item.missing {
  background-color: indianred;
  color: white;
}

.form-number {
  flex: 1;
  font-weight: bold;
}

.form-title {
  flex: 3;
}
