.DayPickerInput {
  width: 40%;
  margin-bottom: 8px;
}

.DayPickerInput > input {
  width: 100%;
  height: 34px;
  padding: 8px;
  font-size: 85%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.InputFromTo > .clearButton {
  border: 0;
  background: none;
}
