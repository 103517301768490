.App {
  text-align: center;
}

section.main {
  padding: 2vh;
  min-height: 65vh;
}

.footer {
  text-align: left;
}

.App-link {
  display: flex;
  flex: 1;
  font-size: 18px;
  margin: 4px 32px;
  cursor: pointer;
}

.auth-block {
  display: flex;
  align-items: center;
}

.auth-block > span {
  font-size: 14px;
  margin-right: 1vw;
  margin-bottom: 3px;
}

.external {
  min-height: 65vh;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.Toastify__toast-body {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-nav {
  z-index: 120;
}

/* overwrites */
footer.overwrite {
  padding: 28px 30px;
  position: inherit;
  width: 100%;
}

footer.overwrite section.disclaimer {
  width: 100%;
  min-height: 56px;
  padding-top: 0;
}
footer.overwrite section.disclaimer p {
  max-width: none;
  padding: 0 250px;
  margin:0;
  line-height: 56px;
}

@media only screen and (max-width: 1200px) {
  footer.overwrite section.disclaimer p {
    padding-right: 0;
  }
}

footer.overwrite section.disclaimer p span {
  line-height: 16px;
  vertical-align: middle;
  display: inline-block;
}

.form-label {
  margin-bottom: 0.4rem;
}

/* Spinner animation */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.fa-spinner {
  animation: spinner 1s linear infinite;
}
